<template>
    <div class="modal fade" tabindex="-1" id="AdminAddUserModal" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Ajouter un nouvel Utilisateur</h5>
                <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="loaded">
                <form class="form">
                    <div class="mb-3">
                        <label for="" class="form-label">Email de connexion</label>
                        <input type="email" class="form-control" v-model="newUser">
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Mot de passe</label>
                        <input type="password" class="form-control" disabled placeholder="non disponible pour le moment">
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Confirmation</label>
                        <input type="password" class="form-control" disabled>
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Type d'utilisateur</label>
                        <select name="" id="" class="form-select" v-model="level" required :disabled="userInfo.authLevel > 0">
                            <option value="2" selected="selected">User</option>
                            <option value="1" v-if="userInfo.authLevel === 0">Modérateur</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="modal-body" v-else>
                <p>Loading...</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click.prevent="close()">Annuler</button>
                <button type="button" class="btn btn-primary" @click.prevent="add" id="addBtn">Créer</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import axios from "axios";
    import {ref, defineProps, onMounted, defineEmits} from "vue";
    import Modal from "bootstrap/js/dist/modal";

    let loaded = ref(false);

    let newUser = ref("");
    let level = ref("2");

    let emit = defineEmits([
        "close",
        "error",
        "added"
    ])

    defineProps([
        "show",
        "userInfo"
    ])

    onMounted( () => {
        let myModal = Modal.getOrCreateInstance(document.querySelector('#AdminAddUserModal'));
        myModal.show();
        loaded.value = true;
    })

    function close(){
        let myModal = Modal.getOrCreateInstance(document.querySelector('#AdminAddUserModal'));
        myModal.hide();
        emit("close");
    }

    function add(e){
        axios.post("/api/users", {
            email: newUser.value,
            authLevel: Number.parseInt(level.value)
        })
        .then( answer => {
            emit("added", answer);
            close();
        })
        .catch( e => {
            alert(e.message);
            emit("error",e);
        })
    }

</script>