<template>

    <div class="alert alert-warning" v-if="userInfo.authLevel > 0" role="alert">
        <h5 class="alert-heading"><i class="bi bi-exclamation-triangle-fill me-2"></i>Attention</h5>
        <p>Votre niveau d'abilitation ne vous permet pas de :
            <ul>
                <li>Voir les utilisateurs des autres modérateurs/admin</li>
                <li>Créer des utilisateurs privilégiés</li>
            </ul></p>
    </div>

    <div class="container-fluid" v-if="loaded">
        <p class="text-end">
            <button class="btn btn-sm btn-primary" @click.prevent="showModal">
                <i class="bi bi-plus"></i>
                Ajouter
            </button>
        </p>

        <table class="table">
            <thead>
                <tr>
                    <th>Etat</th>
                    <th>Nom & Prénom</th>
                    <th>Email</th>
                    <th>Heures consommées</th>
                    <th class="text-center">Niveau</th>
                    <th class="text-end">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="users.length === 0">
                    <td colspan="5" class="text-center"><i class="bi bi-info-circle-fill me-2"></i>Aucun utilisateurs</td>
                </tr>
                <tr v-else v-for="user in users" :key="user._id">
                    <td><i v-if="user.authLevel !== 0" @click="setIsActive(user)" role="button" class="bi" :class="{'bi-arrow-up-circle-fill text-success': user.isActive, 'bi-arrow-down-circle-fill text-muted': !user.isActive}"></i></td>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td class="text-center align-middle">
                        <div class="progress bg-secondary">
                            <div class="progress-bar" :style="'width: '+ (user.runningHours / (user.quota_id.maxHours*user.quota_id.maxInstances) )*100+'%;'" role="progressbar" :aria-valuenow="(user.runningHours / (user.quota_id.maxHours*user.quota_id.maxInstances) )*100" aria-valuemin="0" aria-valuemax="100">
                                <span v-if="(user.runningHours/user.quota_id.maxInstances) > 10">
                                    {{ user.runningHours.toFixed(2) }} / {{ user.quota_id.maxHours*user.quota_id.maxInstances }}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">{{ user.authLevel }}</td>
                    <td class="text-end">
                        <div class="btn-group" role="group" aria-label="Basic example" v-if="user.authLevel > 0">
                            <button type="button" class="btn btn-sm btn-primary disabled"><i class="bi bi-pencil"></i></button>
                            
                            <button type="button" class="btn btn-sm btn-primary" @click="setIsActive(user)">
                                <i class="bi bi-eye-slash" v-if="user.isActive"></i>
                                <i class="bi bi-eye" v-else></i>
                            </button>

                            <button type="button" class="btn btn-sm btn-danger" @click="deleteUser(user)" v-if="userInfo.authLevel === 0"><i class="bi bi-trash"></i></button>
                        </div>
                        <i class="bi bi-lock-fill" v-else></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="container-fluid" v-else>
        <p>Loading...</p>
    </div>

    
    <admin-add-user-vue v-if="show" :user-info="userInfo" @close="closeModal" @added="load"/>

</template>

<script setup>

    import AdminAddUserVue from './modals/AdminAddUser.vue';

    import { defineProps, onMounted, ref } from '@vue/runtime-core';
    import axios from 'axios';

    let users = ref([]);
    let loaded = ref(false);
    let error = ref(null);
    let show = ref(false);

    defineProps([
        "userInfo"
    ]);

    /**
     * Load all groups
     */
    onMounted( () => {
        load()
    })
    
    function load(){
        axios.get("/api/users")
            .then( (response) => {
                users.value = response.data;
                loaded.value = true;
            })
            .catch( (e) => {
                error.value = (e.response) ? e.response.data.message : e ;
            })
    }
    
    function showModal(){
        show.value = true;
    }

    function closeModal(){
        show.value = false;
    }

    function setIsActive(user){
        axios.put("/api/users/"+user._id, {
            isActive: !user.isActive
        })
        .then( answer => {
            load();
        })
        .catch( e => {
            alert(e);
        })
    }

    function deleteUser(user){
        axios.delete("/api/users/"+user._id)
        .then( answer => {
            load();
        })
        .catch( e => {
            alert(e);
        })
    }


</script>